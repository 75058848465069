import { actionCreator } from '@ardoq/rxbeach';
import {
  PayloadDiffModeUpdated,
  PayloadLoadDiff,
  PayloadLoadedScenarioData,
  PayloadOpenAddToScenarioDialog,
  PayloadOpenScenario,
  PayloadReloadScenario,
  PayloadScenarioError,
  PayloadActiveScenarioId,
  PayloadScenarioIsInSync,
  PayloadUpdateEnhancedScopeDiffData,
  PayloadUpdateLoadedScenarioData,
  PayloadUpdateScopeDiff,
} from './types';

export const openScenario = actionCreator<PayloadOpenScenario>(
  '[scope] OPEN_SCENARIO'
);

export const reloadScenario = actionCreator<PayloadReloadScenario>(
  '[scope] RELOAD_SCENARIO'
);

export const reloadScenarioSuccess = actionCreator(
  '[scope] RELOAD_SCENARIO_SUCCESS'
);

export const reloadScenarioError = actionCreator(
  '[scope] RELOAD_SCENARIO_FAIL'
);

export const closeLoadingScenarioProgressBar = actionCreator(
  '[scope] CLOSE_SCENARIO_PROGRESS'
);

export const openScenarioSuccess = actionCreator(
  '[scope] OPEN_SCENARIO_SUCCESS'
);

export const openScenarioError = actionCreator('[scope] OPEN_SCENARIO_ERROR');

export const closeScenario = actionCreator('[scope] CLOSE_SCENARIO');

export const closeScenarioSuccess = actionCreator(
  '[scope] CLOSE_SCENARIO_SUCCESS'
);

export const closeScenarioError = actionCreator<PayloadScenarioError>(
  '[scope] CLOSE_SCENARIO_ERROR'
);

export const setScenarioId = actionCreator<PayloadActiveScenarioId>(
  '[scope] SET_SCENARIO_ID'
);

export const setScenarioIsInSync = actionCreator<PayloadScenarioIsInSync>(
  '[scope] SET_SCENARIO_IS_IN_SYNC'
);

export const openAddToScenarioDialog =
  actionCreator<PayloadOpenAddToScenarioDialog>(
    '[scope] ADD_COMPONENTS_TO_SCENARIO_DIALOG'
  );

export const loadDiff = actionCreator<PayloadLoadDiff>('[scope] LOAD_DIFF');
export const loadDiffSuccess = actionCreator('[scope] LOAD_DIFF_SUCCESS');
export const loadDiffError = actionCreator('[scope] LOAD_DIFF_ERROR');

export const updateScopeDiff = actionCreator<PayloadUpdateScopeDiff>(
  '[scope] UPDATE_SCOPE_DIFF'
);

export const setLoadedScenarioData = actionCreator<PayloadLoadedScenarioData>(
  '[scope] SET_LOADED_SCENARIO_DATA'
);

export const updateLoadedScenarioData =
  actionCreator<PayloadUpdateLoadedScenarioData>(
    '[scope] UPDATE_LOADED_SCENARIO_DATA'
  );

export const diffModeUpdated = actionCreator<PayloadDiffModeUpdated>(
  '[scope] DIFF_MODE_UPDATED'
);

export const updateEnhancedScopeDiffData =
  actionCreator<PayloadUpdateEnhancedScopeDiffData>(
    '[scope] UPDATE_ENHANCED_SCOPE_DIFF_DATA'
  );
