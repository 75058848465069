import { LoadedGraphWithViewpointMode } from '@ardoq/graph';
import { LoadedGraph } from '@ardoq/graph';
import { enhancedScopeDataOperations } from '@ardoq/scope-data';
import { ClearTraversalAndSearchStatePayload } from 'streams/traversals/actions';

const replaceScopeComponentIds = (
  state: LoadedGraphWithViewpointMode,
  loadedGraph: LoadedGraph
): LoadedGraphWithViewpointMode => {
  return {
    ...loadedGraph,
    isViewpointMode: true,
  };
};

const getEmpty = (): LoadedGraphWithViewpointMode => {
  return {
    scopeComponentIds: [],
    scopeReferenceIds: [],
    isViewpointMode: false,
    parentChildReferences: [],
    hierarchyDefinition: [],
    componentParentMap: new Map(),
    scopeData: enhancedScopeDataOperations.getEmpty(),
  };
};

const clearLoadedGraphState = (
  state: LoadedGraphWithViewpointMode,
  { keepCurrentViewPointMode }: ClearTraversalAndSearchStatePayload
): LoadedGraphWithViewpointMode => {
  return {
    ...getEmpty(),
    isViewpointMode: keepCurrentViewPointMode ? state.isViewpointMode : false,
  };
};
const enterViewpointMode = (state: LoadedGraphWithViewpointMode) =>
  state.isViewpointMode
    ? state
    : {
        ...state,
        isViewpointMode: true,
      };

export const loadedGraphOperations = {
  getEmpty,
  replaceScopeComponentIds,
  clearLoadedGraphState,
  enterViewpointMode,
};
