import { action$, reducer, reduceState } from '@ardoq/rxbeach';
import { PayloadUpdateScopeDiff, ScopeDiffState } from 'scope/types';
import { loadDiff, loadDiffError, updateScopeDiff } from 'scope/actions';

const handleUpdateDiff = (
  _: ScopeDiffState,
  { scopeDiff }: PayloadUpdateScopeDiff
) => ({ scopeDiff, isLoading: false });

const handleLoadDiffError = ({ scopeDiff }: ScopeDiffState) => ({
  scopeDiff,
  isLoading: false,
});

const handleLoadDiff = ({ scopeDiff }: ScopeDiffState) => ({
  scopeDiff,
  isLoading: true,
});

const defaultState: ScopeDiffState = {
  isLoading: false,
  scopeDiff: null,
};

const reducers = [
  reducer(loadDiff, handleLoadDiff),
  reducer(updateScopeDiff, handleUpdateDiff),
  reducer(loadDiffError, handleLoadDiffError),
];

export const scopeDiff$ = action$.pipe(
  reduceState<ScopeDiffState>('scopeDiff$', defaultState, reducers)
);
