interface GetIn {
  <
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    K4 extends keyof T[K1][K2][K3],
    D = never,
    R = T[K1][K2][K3][K4],
  >(
    object: T | undefined | null,
    path: [K1, K2, K3, K4],
    defaultValue?: D
  ): R | D;
  <
    T,
    K1 extends keyof T,
    K2 extends keyof T[K1],
    K3 extends keyof T[K1][K2],
    D = never,
    R = T[K1][K2][K3],
  >(
    object: T | undefined | null,
    path: [K1, K2, K3],
    defaultValue?: D
  ): R | D;
  <T, K1 extends keyof T, K2 extends keyof T[K1], D = never, R = T[K1][K2]>(
    object: T | undefined | null,
    path: [K1, K2],
    defaultValue?: D
  ): R | D;
  <T, K1 extends keyof T, D = never, R = T[K1]>(
    object: T | undefined | null,
    path: [K1],
    defaultValue?: D
  ): R | D;

  // We need to separate the untyped overload with and without default, because
  // the untyped overload with default will only ever return the R or D type,
  // never undefined
  /**
   * **NB: UNTYPED**
   * You might have reached this untyped overload if:
   * * The path is longer than four element, or
   * * The path looks up optional keys in the object
   *
   * Remember that you can set the return type as a generic:
   *
   *     getIn<ReturnType | null>(input, ['optionalKey', 'fieldWithReturnType'], null);
   *
   */
  <R = unknown>(object: any, path: (string | number)[], defaultValue: R): R;
  /**
   * **NB: UNTYPED**
   * You might have reached this untyped overload if:
   * * The path is longer than four element, or
   * * The path looks up optional keys in the object
   *
   * Remember that you can set the return type as a generic:
   *
   *     getIn<ReturnType>(input, ['optionalKey', 'fieldWithReturnType']);
   *
   */
  <R = unknown>(object: any, path: (string | number)[]): R | undefined;
}
export const getIn: GetIn = (
  object: any,
  path: any,
  defaultValue?: any
): any => {
  if (path.length > 0) {
    const newOb = get(object, path[0], undefined);
    return newOb === undefined
      ? defaultValue
      : getIn(newOb, path.slice(1), defaultValue);
  }
  return object;
};

interface Get {
  <R, K, T extends { get: (key: K) => R }, D>(
    object: T | undefined | null,
    key: K,
    defaultValue: D
  ): R | D;
  <T, D, K extends keyof T>(
    object: T | undefined | null,
    key: K,
    defaultValue: D
  ): T[K] | D;
  <T, K extends keyof T>(object: T | undefined | null, key: K): T[K];
}
export const get: Get = (
  object: any,
  key: string | number,
  defaultValue?: any
) => {
  if (typeof object === 'object' && object !== null) {
    const newOb = object[key];
    if (newOb === undefined && typeof object.get === 'function') {
      const value = object.get(key);
      return value === undefined ? defaultValue : value;
    }
    return newOb === undefined ? defaultValue : newOb;
  }
  return defaultValue;
};

export const isUndefinedOrNull = <T>(
  x: T | null | undefined
): x is null | undefined => x === null || x === undefined;

export const filterUnique = <T>(item: T, index: number, all: T[] = []) =>
  all.indexOf(item) === index;

/** @deprecated Don't use in new code, it's too slow. */
export const isUnique =
  <T>(key: keyof T | ((a: T) => any)) =>
  (a: T, index: number, all: T[]) =>
    index ===
    all.findIndex((b: T) => {
      if (typeof key === 'string') {
        return a[key] === b[key];
      } else if (typeof key === 'function') {
        return key(a) === key(b);
      }
    });

/** @deprecated Don't use in new code, it's too slow. */
export const isUniqueCombination =
  <T>(key1: keyof T, key2: keyof T) =>
  (a: T, index: number, all: T[]) =>
    index ===
    all.findIndex((b: T) => a[key1] === b[key1] && a[key2] === b[key2]);

interface DeepCopyObject {
  (func: (...args: unknown[]) => unknown): undefined;
  <T extends any[]>(array: T): T;
  <T extends object>(object: T): T;
}
/**
 * Utility function to make deep copies of objects
 * Does not handle cyclic objects (throws RangeError)
 * Purges functions (leaves them as undefined)
 */
export const deepCopyObject: DeepCopyObject = (object: any) => {
  if (typeof object === 'function') return undefined;
  if (typeof object !== 'object' || !object) return object;
  if (Array.isArray(object)) {
    return object.map(x => deepCopyObject(x));
  }

  return Object.keys(object).reduce((newObject, key) => {
    newObject[key] = deepCopyObject(object[key]);
    return newObject;
  }, {} as any);
};
