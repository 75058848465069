import { actionCreator } from '@ardoq/rxbeach';
import { PayloadShowAppModule } from './types';
import { AssetType } from '@ardoq/api-types';
import { GetContentOptions } from 'appModelStateEdit/legacyTypes';

export const requestShowAppModule = actionCreator<PayloadShowAppModule>(
  '[appContainer] REQUEST_SHOW_APP_MODULE'
);

export const showAppModule = actionCreator<PayloadShowAppModule>(
  '[appContainer] SHOW_APP_MODULE'
);

export const rejectedNavigation = actionCreator<PayloadShowAppModule>(
  '[appContainer] REJECTED_SHOW_APP_MODULE'
);

export type TogglePresentationEditorPayload = {
  shouldShow: boolean;
};
export const togglePresentationEditor =
  actionCreator<TogglePresentationEditorPayload>(
    '[appContainer] TOGGLE_PRESENTATION_EDITOR'
  );

export const openProductboardPortal = actionCreator(
  '[appContainer] OPEN_PRODUCTBOARD_PORTAL'
);

export const showRightPane = actionCreator<GetContentOptions>(
  '[appContainer] SHOW_RIGHT_PANE'
);
export const hideRightPane = actionCreator('[appContainer] HIDE_RIGHT_PANE');

export const openHomeWithFilter = actionCreator<AssetType[]>(
  '[appContainer] OPEN_ASSET_MANAGER_WITH_FILTER'
);
